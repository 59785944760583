@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 700;
  src: url('../fonts/SpoqaHanSans/SpoqaHanSansBold.eot');
  src: local('Spoqa Han Sans Bold'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansBold.woff2') format('woff2'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansBold.woff') format('woff'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 400;
  src: url('../fonts/SpoqaHanSans/SpoqaHanSansRegular.eot');
  src: local('Spoqa Han Sans Regular'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansRegular.woff2') format('woff2'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansRegular.woff') format('woff'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 300;
  src: url('../fonts/SpoqaHanSans/SpoqaHanSansLight.eot');
  src: local('Spoqa Han Sans Light'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansLight.woff2') format('woff2'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansLight.woff') format('woff'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 100;
  src: url('../fonts/SpoqaHanSans/SpoqaHanSansThin.eot');
  src: local('Spoqa Han Sans Thin'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansThin.woff2') format('woff2'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansThin.woff') format('woff'),
    url('../fonts/SpoqaHanSans/SpoqaHanSansThin.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 700;
  src: url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.eot');
  src: local('Spoqa Han Sans Bold'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff') format('woff'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  src: url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.eot');
  src: local('Spoqa Han Sans Medium'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff2') format('woff2'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff') format('woff'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 400;
  src: url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.eot');
  src: local('Spoqa Han Sans Regular'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff') format('woff'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  src: url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.eot');
  src: local('Spoqa Han Sans Light'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff2') format('woff2'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff') format('woff'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 100;
  src: url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.eot');
  src: local('Spoqa Han Sans Thin'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff2') format('woff2'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff') format('woff'),
    url('../fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'BebasNeue Regular';
  font-weight: 400;
  src: url('../fonts/BebasNeue/BebasNeue-Regular.ttf');
}